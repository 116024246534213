<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h1>Dashboard</h1>
            </div>
        </div>
        <camera />
    </div>
</template>

<script>
import Camera from './Camera'
export default {
  name: 'Dashboard',
  components: {
    Camera
  },
  data () {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: []
    }
  },
  created () {
    this.findDevices()
  },
  computed: {
    device: function () {
      if (!this.deviceId) {
        return null
      }
      return this.devices.find(n => n.deviceId === this.deviceId)
    }
  },
  watch: {
    deviceId: function (newVal, oldVal) {
      console.log([newVal, oldVal])
    }
  },
  methods: {
    async findDevices () {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const devices = await navigator.mediaDevices.enumerateDevices()
          .then(devices => devices.filter(device => device.kind === 'videoinput'))
        this.devices = devices
      }
    }
  }
}
</script>
