<template>
    <div class="container">
        <!--div class="row">
            <div class="col-md-6">
                <h2>Current Camera</h2>
                <button class="btn btn-primary" @click="findDevices">Find Devices</button>
            </div>
        </div>
        <div class="row">
          {{deviceId}}
          <div class="col-md-6" v-if="devices">
            <select v-model="deviceId">
              <option value="null" >Select Cam</option>
              <option v-for="cam in devices" :key="cam.deviceId" :value="cam.deviceId">
                {{cam.label}}
              </option>
            </select>
            <button @click="capture()">Shot</button>
          </div>
        </div-->
        <div class="row">
          <div class="col-md-6 mt-4" style="position:relative">
            <button @click="start()">Start</button>
            <h3 class="text-center">VIN: {{vin}}</h3>
            <div class="camera-holder">
              <div class="cameraOverlay">
                <div class="image-slot"></div>
              </div>
              <video class="video" id="video" ref="video"
                autoplay
                muted
                playsinline />
              <canvas class="hidden" id="canvas" ref="canvas"></canvas>
            </div>
            <div class="capture-result">
                <img ref="photo" src="" width="375" height="120"/>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Camera from "../misc/camera.js";
import axios from 'axios';

export default {
  components: {
  },
  data () {
    return {
      img: null,
      camera: null,
      torch: null,
      deviceId: null,
      devices: [],
      cameraInstance: null,
      destroyed: false,
      stopScanning: () => {},
      currentStream: null,
      vin: null,
      camera: 'auto',
      resultList: {},
      processId: null
    }
  },
  mounted () {
    this.init()
    //this.findDevices()
  },
  computed: {
    device: function () {
      if (!this.deviceId) {
        return null
      }
      return this.devices.find(n => n.deviceId === this.deviceId)
    }
  },
  watch: {
    deviceId: function (newVal, oldVal) {
      this.video = this.$refs.video
      if (this.currentStream !== null) {
        this.currentStream.getTracks().forEach(track => {
            track.stop()
        })
      }
      
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          const constraints = { audio: false }
          if (newVal) {
              const videoConstraints = {
                deviceId: { exact: newVal }
              }
              constraints.video =  videoConstraints
          } else {
              const videoConstraints = {
                facingMode: 'environment'
              }
              constraints.video =  videoConstraints
          }

          navigator.mediaDevices.getUserMedia(constraints).then(stream => {
            this.currentStream = stream
            this.video.src = ''
            this.video.srcObject = stream
            this.video.play()
          })
      }
    }
  },
  methods: {
    async findDevices () {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const devices = await navigator.mediaDevices.enumerateDevices()
          .then(devices => devices.filter(device => device.kind === 'videoinput'))
        this.devices = devices
      }
    },

    /*capture () {
      let {width, height} = this.currentStream.getTracks()[0].getSettings()
      this.$refs.canvas.width = width
      this.$refs.canvas.height = 120

      console.log(this.currentStream.getTracks()[0].getSettings());

      this.$refs.canvas
        .getContext('2d')
        .drawImage(this.$refs.video, 0, 130, this.$refs.canvas.width, this.$refs.canvas.height, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height)

      const data = this.$refs.canvas.toDataURL('image/png')
      this.$refs.photo.src = data

      axios.post('https://ocr.tecgento.de/predict/ocr_system', {
        images: [data.replace('data:image/png;base64,', '')]
      })
      .then(data => {
          console.log(data)
          data.result.forEach( line => {
            if (line.text.length == 17) {
              this.vin = line.text
            }
          });
      })
      .catch()
    },*/

    start () {
        this.processId = setInterval(this.capture, 1000)
    },

    stop() {
        clearInterval(this.processId);
    },

    checkStop(vin) {
      console.log(this.resultList)
        if (!this.resultList[vin]) {
          this.resultList[vin] = 1
        } else {
          this.resultList[vin] += 1
        }

        if (this.resultList[vin] > 2) {
          this.stop()
        }
    },

    capture () {
      let {width, height} = this.cameraInstance.stream.getTracks()[0].getSettings()
      this.$refs.canvas.width = width
      this.$refs.canvas.height = 120

      this.$refs.canvas
        .getContext('2d')
        .drawImage(this.$refs.video, 0, 100, this.$refs.canvas.width, this.$refs.canvas.height, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height)

      const data = this.$refs.canvas.toDataURL('image/png')
      this.$refs.photo.src = data

      axios.post('https://ocr.tecgento.de/predict/ocr_system', {
        images: [data.replace('data:image/png;base64,', '')]
      })
      .then( response => {
          const data = response.data
          data.results[0].forEach( (function(line) {
            if (line.length == 0) {
              return;
            }

            if (line.text.length == 17) {
              this.vin = line.text
              this.checkStop(line.text);
            }
          }).bind(this));
      })
      .catch()
    },

    init () {
      const promise = (async () => {
        this.beforeResetCamera()
        if (this.camera === 'off') {
          this.cameraInstance = null
          return {
            capabilities: {}
          };
        } else {
          this.cameraInstance = await Camera(this.$refs.video, {
            camera: this.camera,
            torch: this.torch
          });
          const capabilities = this.cameraInstance.getCapabilities()

          if (this.destroyed) {
            this.cameraInstance.stop()
          }

          return {
            capabilities
          };
        }
      })();
      this.$emit("init", promise)
    },

    beforeResetCamera() {
      if (this.cameraInstance !== null) {
        this.cameraInstance.stop()
        this.cameraInstance = null
      }
    }
  },
}
</script>

<style lang="scss" scoped>
    .video {
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: -1;
    }

    .camera-holder{
      width: 375px;
      overflow: hidden;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      height: 281px;
      z-index: 1;
    }

    .mt-4 {
      margin-top: 16px;
    }
    .cameraOverlay {
      .image-slot{
        width: calc(100% - 24px);
        height: 60px;
        background-color: transparent;
        border: 2px solid #FF3300;
        margin-top: 100px;
        margin-left: 12px;
        margin-right: 12px;
        position: absolute;
        box-shadow: 0 10px 0 110px rgba(0, 0, 0, 0.2);
      }
    }

    .capture-result{
      background-color: #fff;
      width: 100%;
      position: absolute;
      height: 30px;
      bottom: -60px;
    }

    .hidden {
      display: none;
    }
</style>
